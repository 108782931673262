<template>
	<div class="visa-total">
		<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/qqqz_tt%402x.png" class="title-icon-1" />
		<div class="content">
			<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/img_qzlc%402x.png" class="title-icon-2" />
			<div class="product-bar">
				<HomeTitleBar :type="Number('10')" title="热门地区" desc="热  门  推   荐" :tab_array="[]"></HomeTitleBar>
				<div class="product-more-bar">
					<img :src="item.url" v-for="item in hot_place" :key="item.id" class="hot-place-icon"
						@click="clickArea(item.id, item.name)" />
				</div>
			</div>
			<div class="product-bar">
				<HomeTitleBar :type="Number('11')" title="热门签证" desc="热  门  推   荐" :tab_array="[]"></HomeTitleBar>
				<div class="product-more-bar">
					<HomeVisaItem v-for="(item, index) in product_visa" :index="index" :key="item.visaId"
						:img_src="item.visaImg" :title="item.visaName" :place="item.Country" :send_place="item.VisaCity"
						:price="item.visaPrice" :desc="item.JXPrice" @clickProductItem="clickProductItem">
					</HomeVisaItem>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getVisaMain
	} from "@/api/index";
	import HomeVisaItem from "../components/Home_Visa_Item.vue";
	import HomeTitleBar from "../components/Home_Title_Bar.vue";
	export default {
		components: {
			HomeTitleBar,
			HomeVisaItem,
		},
		data() {
			return {
				product_visa: [],
				hot_place: [{
						id: 391,
						name: "日本",
						url: "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/qz_japan%402x.png",
					},
					{
						id: 448,
						name: "美国",
						url: "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/qz_usa%402x.png",
					},
					{
						id: 396,
						name: "法国",
						url: "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/faguo.png",
					},
					{
						id: 433,
						name: "马来西亚",
						url: "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/qz_mlxy%402x.png",
					},
					{
						id: 392,
						name: "澳大利亚",
						url: "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/qz_aus%402x.png",
					},
					{
						id: 569,
						name: "加拿大",
						url: "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/qz_canad%402x.png",
					},
					{
						id: 404,
						name: "英国",
						url: "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/qz_uk%402x.png",
					},
					{
						id: 470,
						name: "新西兰",
						url: "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/qz_xxl%402x.png",
					},
				],
			};
		},
		created() {
			this.getVisaMain();
		},
		methods: {
			clickArea(id, name) {
				let org = this.$route.query.org ? this.$route.query.org : ''
				window.open(
					"/VisaMoreProduct?areaid=" + id + "&areaname=" + name + "&tab_name=7&org=" + org,
					"_blank"
				);
			},
			clickProductItem(e) {
				let index = e.index;
				let org = this.$route.query.org ? this.$route.query.org : ''
				window.open(
					"/VisaDetail?id=" + this.product_visa[index].visaId + "&tab_name=7&org=" + org,
					"_blank"
				);
			},
			getVisaMain() {
				getVisaMain().then((res) => {
					this.product_visa = res.visalist;
				});
			},
		},
	};
</script>

<style lang='less' scoped>
	.visa-total {
		display: flex;
		width: 100vw;
		flex-direction: column;
		align-items: center;
		background-color: #fff;

		.title-icon-1 {
			width: 100%;
		}

		.content {
			width: 1200px;
			padding-bottom: 100px;

			.title-icon-2 {
				margin-top: 20px;
				margin-bottom: 20px;
				width: 100%;
			}

			.hot-place-icon {
				width: 285px;
				height: 200px;
				cursor: pointer;
				margin-bottom: 20px;
			}

			.product-bar {
				display: flex;
				z-index: 1;
				width: 1200px;
				flex-direction: column;

				.product-more-bar {
					display: flex;
					width: 100%;
					flex-wrap: wrap;
					justify-content: space-between;


				}


			}
		}
	}
</style>